import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="About Images"/>
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Unique Business <br/>
                                    <Typed className="theme-gradient"
                                           strings={[
                                               "Aerospace",
                                               "Finance",
                                               "Transport",
                                               "Telecommunication",
                                               "Education",
                                               "Pharmaceutical",
                                               "Health and Care",
                                               "Hospitality",
                                               "Travel",
                                               "Gambling",
                                               "Entertainment",
                                               "News",
                                               "Advertising",
                                               "Fashion",
                                               "Publishing",
                                               "Infrastructure",
                                               "Public Services",
                                           ]}
                                           typeSpeed={80}
                                           backSpeed={10}
                                           backDelay={1200}
                                           loop
                                    />Industry
                                </h2>
                                <p>
                                    Your unique business will benefit immensely from our highly trained IT contractors,
                                    who can help your business to deliver projects in a fast and modern manner.
                                </p>
                                <p>
                                    Codebest is made of a highly skilled team of contractors with an amazing
                                    professional background.
                                </p>
                                <p>
                                    We have been in the market for more than 10 years. We delivered real and solid
                                    results, backed by our proven track record.
                                </p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/about-us"><span>More About Us</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne
