import React from 'react';
import Slider from "react-slick";
import {slickDot} from "../../utils/script";

const TestimonialData = [
    {
        form: "Bet365.com",
        description: "Codebest contributed by engineering core backend services responsible for backoffice operations and public APIs currently used by bet365.com website frontend.",
        name: "Backend development",
        subtitle: "Agile development",
        image: "testimonial-01"
    },
    {
        form: "United Kingdom Government",
        description: "Codebest contributed by engineering core backend services responsible for backoffice operations as part of the Health Programme.",
        name: "Backend development",
        subtitle: "Agile development",
        image: "testimonial-02"
    },
    {
        form: "Booking.com",
        description: "Codebest integrants contributed by engineering core backend services responsible for payment and booking operations on their Rides solution.",
        name: "Backend development",
        subtitle: "Agile Development",
        image: "testimonial-03"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="mt_sm--40">
                                        <h3 className="form subtitle">{data.form}</h3>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`}
                                             alt="Corporate React Template"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















