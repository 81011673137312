import React from 'react';
import {
    FiActivity,
    FiAirplay,
    FiAlertTriangle,
    FiAnchor,
    FiArchive, FiArrowDownCircle,
    FiBarChart, FiBriefcase,
    FiCast, FiCircle, FiFacebook, FiGrid,
    FiMap, FiMenu,
    FiSave, FiServer
} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {
    AiFillCheckCircle, AiFillCloud, AiFillCompass, AiFillLock,
    AiFillMobile, AiFillQqCircle, AiFillSecurityScan, AiOutlineCompass, BiRuler, BsFillPentagonFill, BsLayers, FaMobile,
    FcEngineering,
    GiArchitectMask,
    GiBoatEngine,
    GrTest,
    ImLab
} from "react-icons/all";
import Company from "../../pages/Company";
import MobileMenu from "../../common/header/MobileMenu";

const ServiceList = [
    {
        icon: <FiActivity/>,
        title: 'Agile Development',
        description: 'Agile is the ability to create and respond to change. It allows ultimately to obtain success in an uncertain and turbulent environment.'
    },
    {
        icon: <FcEngineering/>,
        title: 'Backend Development',
        description: 'It is all the services that the users do not see but that executes behind-the-scenes activities when performing an action on a given system.'
    },
    {
        icon: <FiGrid/>,
        title: 'Frontend Development',
        description: 'It is all about the experience that the users have when utilising interface components when performing any action on a given application.'
    },
    {
        icon: <FaMobile/>,
        title: 'Mobile Development',
        description: 'Mobile app development is a process that can be focused on creating software that takes advantage of the unique features of mobile device hardware.'
    },
    {
        icon: <FiServer/>,
        title: 'Devops Culture',
        description: 'It is all about sharing understanding and responsibility between developers and operations. Increasing transparency, communication, and collaboration across the business.'
    },
    {
        icon: <ImLab/>,
        title: 'Test Driven Development',
        description: 'Test-driven development (TDD) is a software development process relying on software requirements being converted to test cases before software is fully developed.'
    },
    {
        icon: <BsLayers/>,
        title: 'End to End Architecture',
        description: 'It informs where the best opportunity exists based upon the strategic contribution of each process and its health relative to needs of the client.'
    },
    {
        icon: <AiFillLock/>,
        title: 'Data Security',
        description: 'It is a set of security services and frameworks that allow the creation of a secure infrastructure for client/server applications and services.'
    },
    {
        icon: <AiFillCloud/>,
        title: 'Cloud Infrastructure',
        description: 'Cloud infrastructure is delivered via on-demand services, leveraging in third party infrastructure to boot and expand the clients operations.'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    {val.title}
                                </h4>
                                <p className="description b1 color-gray mb--0">
                                    {val.description}
                                </p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;