import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import {FiArrowRight} from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Our Unique Mission is to provide {" "}
                                <Typed className="theme-gradient"
                                       strings={[
                                           "Guidance.",
                                           "Transformation.",
                                           "Expansion.",
                                           "Innovation.",
                                           "Positive Results.",
                                       ]}
                                       typeSpeed={80}
                                       backSpeed={5}
                                       backDelay={1000}
                                       loop
                                />
                            </h2>
                            <p>
                                We aim to transform challenges into growth opportunities,
                                by providing our clients with the absolute best in class technics, tools and solutions.
                            </p>
                            <p>
                                Software Engineering is our passion.
                            </p>
                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/blog-grid">
                                    Learn More
                                    <i className="icon"><FiArrowRight/></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default mission;
