import React from 'react'
import {Accordion, Card, Button} from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Why consulting with <b>Codebest</b> services?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <p>
                            Our proven track record allows for rapid development of new software, frameworks, tools, and
                            techniques improving operations in a number of companies.
                        </p>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        How that comes to be?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <p>
                            Our exposure to varying combinations of business circumstances and experiences of different
                            companies have allowed for the accumulation of valuable knowledge and business experience.
                        </p>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        How consultancy experience can help my business?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <p>
                            Modern business is characterized by risk and uncertainty and such an environment
                            is reflected in the decisions taken by the organisation leaders. The adoption of consulting
                            is then one of the most important management techniques developed over the last fifty
                            years. It allows organisation leaders to concentrate efforts on business core activities,
                            introduction of new and more productive and healthy ways of working
                            and it also relieves delivery pressure allowing profound transformations and faster time to
                            market.
                        </p>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

