import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import TeamTwo from "../elements/team/TeamTwo";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us"/>
            <main className="page-wrapper">
                <HeaderTopNews/>
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">ABOUT US.</span>
                                        </h3>
                                    </div>
                                    <h1 className="title display-one">A software development consultancy agency focused
                                        on business
                                        transformation</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">From every corner of the globe</h3>
                                    <h6 className="content">
                                        Transforming challenges into growth opportunities.
                                    </h6>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">You probably already know us better than you think. If you use the
                                    United Kingdom Government website, organise your holidays in booking.com, bet in
                                    bet365.com or shop at loja.impresa.pt, then you've already
                                    experienced our work, first-hand.
                                    <br/>
                                    <br/>
                                    Our goal is to transform challenges into growth opportunities. That way our clients
                                    can improve the lives of their clients. Together, we design, build
                                    and deliver transformative digital solutions for the world's best-known brands.
                                    <br/>
                                    <br/>
                                    Whatever brought you to us, whether it was work, play or something in between, we
                                    build intuitive, frictionless and connected
                                    experiences that improve human lives and make our clients' businesses grow.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                {/* End Service Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Services"
                                    description="We are proud to provide the following services"
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}
                <br/><br/>
                <Separator/>
                {/* Start Team Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Experts Network"
                                    title="Experts"
                                    description="Feel free to check their profiles"
                                />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two"/>
                    </div>
                </div>
                <Separator/>
                {/* End Team Area  */}
                <FooterTwo/>
                <Copyright/>
            </main>
        </>
    )
}

export default AboutUs;
