import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import {FiArrowRight} from "react-icons/fi";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import ServiceOne from "../elements/service/ServiceOne";
import HeaderOne from "../common/header/HeaderOne";

const BlogListData = BlogClassicData.slice(0, 3);


const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="Business Consulting"/>
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent"/>
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">SOFTWARE DEVELOPMENT CONSULTANCY</span>
                                    <h1 className="title theme-gradient display-two">CODEBEST <br/> {" "}
                                        <Typed
                                            strings={["Agile.", "Software.", "Development.", "Agency.",]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">
                                        We help our clients succeed by driving innovation and
                                        quality engineering into challenges.
                                    </p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon"
                                           href="/about-us">
                                            Know more <i className="icon"><FiArrowRight/></i>
                                        </a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                            Contact Us <i className="icon"><FiArrowRight/></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start About Area  */}
                <AboutOne/>
                {/* End About Area  */}

                <Separator/>
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Services"
                                    description="We are proud to provide the following services"
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator/>
                {/* Start Team Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Experts Network"
                                    title="Experts"
                                    description="Feel free to check their profiles"
                                />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two"/>
                    </div>
                </div>
                {/* End Team Area  */}
                <Separator/>
                {/* Start Mission Area   */}
                <Mission/>
                {/* Start Mission Area  */}


                <Separator/>
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Success cases"
                                    title="Awesome Clients"
                                    description="A few of our many success stories"
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle=""/>
                    </div>
                </div>
                {/* End Testimonial Area  */}

                <Separator/>
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Latests News"
                                    title="Our Latest News."
                                    description="We provide software development service for business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <FooterTwo/>
                <Copyright/>
            </main>
        </>
    )
}
export default BusinessConsulting2;
