import React from 'react';
import {FiFacebook, FiTwitter, FiInstagram, FiLinkedin} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-01',
        name: 'Vinicius Alvarenga',
        designation: 'CEO',
        location: 'Manchester, UK',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/vinicius-alvarenga-profile/'
            },
        ]

    },
    {
        image: 'team-02',
        name: 'Priscila Catalão',
        designation: 'CFO',
        location: 'Manchester, UK',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/priscila-cardozo-9a380278/'
            },
        ]

    },
    {
        image: 'team-03',
        name: 'Carlos Ramos',
        designation: 'Lead Product Owner',
        location: 'Rio de Janeiro, Brazil',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/alvarengajuniorbr/'
            },
        ]

    },
    {
        image: 'team-04',
        name: 'Fabio Valente',
        designation: 'Lead DevOps',
        location: 'London, UK',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/fabiojvalente/'
            },
        ]
    },
    {
        image: "team-05",
        name: "Mario Melo",
        designation: "Lead SEO & UX",
        location: 'Juiz de Fora, Brazil',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/mario-melo/'
            },
        ]
    },
    {
        image: "team-06",
        name: "Tiago Dias",
        designation: "Lead Developer",
        location: 'Lisbon, Portugal',
        description: '',
        socialNetwork: [
            {
                icon: <FiLinkedin/>,
                url: 'https://www.linkedin.com/in/tiagoftdias/'
            },
        ]
    }
]

const TeamTwo = ({column, teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template"/>
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template"/>
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20">
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a target={"_blank"} href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
