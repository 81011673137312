import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {FiArrowDownCircle} from "react-icons/fi";

const pin = {
    color: "red",
};
const AnyReactComponent = ({text}) => <div><p style={pin}><b>{text}</b></p></div>;

class GoogleMapStyle extends Component {
    static defaultProps = {
        center: {
            lat: 53.4609,
            lng: -2.1854
        },
        zoom: 14
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div className="google-map-style-1">
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyBYMePpZnmaeRSlbsEt61Xcxe2STczJRRY"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={53.4609}
                        lng={-2.1854}
                        text="CODEBEST"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMapStyle;